import React from 'react';
import usdt from './usdt.png';
import busd from './favicon.ico';
import './App.css';
import './bootstrap.min.css';
import './custome-style.css';
import { useState } from 'react';
import BigNumber from "bignumber.js";
import logo from './arrdown.svg';
// import './bootstrap.bundle.min.js';
import $ from 'jquery'
import axios from 'axios';
import { createStandaloneToast } from '@chakra-ui/toast'
import toast, { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import { click } from '@testing-library/user-event/dist/click';





function App() {

 
// const { ToastContainer, toast } = createStandaloneToast()
  const [address, setAddress] = useState("");
  const [pay, setPay] = useState('');
  const[transcheck,setTranscheck]=useState('');
  const[input,setinput]=useState('');
  const[history,setHistory]=useState([]);
  const[sourceToken, setSourceToken] = useState('USDT');
  const[destinationToken, setDestinationToken] = useState('SUSD');
  const[showBalErr,setBalErr]=useState(false);
  const[usdtBalance,setusdtBalance]=useState('');
  const[susdBalance,setsusdBalance]=useState('');
  const[allowSwap,setAllowswap]=useState(false);

  
  var tronWeb;

  const handleWalletConnect = async () => {
   
  if (window.tronWeb) {
        tronWeb = window.tronWeb;
        var wallet=tronWeb.defaultAddress.base58;
      if(!wallet){
        toast.error('Please Unlock your Tronlink Extension');
        return false;
      }
      console.log(tronWeb.eventServer.host);
      // Refresh the page to update the TronWeb instance
        if(tronWeb.eventServer.host!="https://api.trongrid.io"){
          toast.error('Please choose TRONGRID Mainnet');
          return false

        }
       
       
        setAddress(wallet);
        checkBalance()
        toast.success('TronLink Wallet connected')
       
      }else{
        toast.error('Make sure Tronlink installed');
        return false;
      }
  
  }
 async function checkBalance(){
  tronWeb=window.tronWeb;
  var sourceContract=sourceToken=='USDT' ? 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t':'TXBRbSCyDb1DYVMiNjpSLWz9HKR54tQvNX';
     
  var destinContract=destinationToken=='SUSD' ? 'TXBRbSCyDb1DYVMiNjpSLWz9HKR54tQvNX':'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t';
 
  const fromcontract=await tronWeb.contract().at(sourceContract).catch((err)=>{
    throw err
   });
  var fromcontract_bal=await fromcontract.balanceOf(tronWeb.defaultAddress.base58).call()/10**6;
  if(Number(fromcontract_bal)<=0){
    sourceToken=='USDT' ?  setusdtBalance(0) : setsusdBalance(0);
    setBalErr(true);
  }else if(Number(fromcontract_bal) < Number(pay)){
    setBalErr(true);
  }
  else{
    sourceToken=='USDT' ?  setusdtBalance(fromcontract_bal) : setsusdBalance(fromcontract_bal);
    setAllowswap(true)
  }

  const tocontract=await tronWeb.contract().at(destinContract).catch((err)=>{
    throw err
   });
  var tocontract_bal=await tocontract.balanceOf(tronWeb.defaultAddress.base58).call()/10**6;

  console.log(tocontract_bal+'to_bal')
  if(tocontract_bal<=0){
    destinationToken=='SUSD' ?setsusdBalance(0) :  setusdtBalance(0);
  }else if(Number(tocontract_bal) < Number(pay)){
    setBalErr(true);
  }
  else{
    destinationToken=='SUSD' ?  setsusdBalance(tocontract_bal) : setusdtBalance(tocontract_bal);
  }
 }
  const calculatePay = async(value) => {
    setBalErr(false);
    if(!address){
      toast.error('Please Connect Wallet and then enter Amount');
      return false;
    }
    setTranscheck('')
    setPay(value);
    checkBalance();
   
  }
  var balance;
  const redirecttoHash=(hash)=>{
    window.open("https://nile.tronscan.org/?_ga=2.247428228.1272305720.1681807451-1803999093.1681807451#/address/"+address ,"_blank");
  }
 
  
  const swapToken = async() => {
    const tokenContract = sourceToken=='USDT' ? 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t':'TXBRbSCyDb1DYVMiNjpSLWz9HKR54tQvNX';
    const swapContract = 'TL8zTq9TCEx4MQL2usRQRr4hxpowNTSTH7';
    var tronWeb=window.tronWeb;
    if(tronWeb.eventServer.host!="https://api.trongrid.io"){
      toast.error('Please choose TRONGRID Mainnet');
      return false
    }
    let wallet= tronWeb.defaultAddress.base58;
    if(Number(pay) <= 0){
      toast.error('Please Enter Amount greater than 0')
      return false
    }
   
    toast.loading('Please wait Dont refresh...',{
      duration: 15000,
    });
    if(tronWeb.eventServer.host!="https://api.trongrid.io"){
      toast.error('Please choose TRONGRID Mainnet');
      return false
    }
    const check=await tronWeb.trx.getAccount(address)
    const contract=await tronWeb.contract().at(tokenContract).catch((err)=>{
      throw err
     });
    let contract_bal=await contract.balanceOf(wallet).call()/10**6;
    if(contract_bal<Number(pay)){
      toast.error('Entered amount exceeds SUSD Token balance');
      return false;
    }
    if(tronWeb.eventServer.host!="https://api.trongrid.io"){
      toast.error('Please choose TRONGRID Mainnet');
      return false
    }
    let amount=BigNumber(pay * 10 ** 6).toString()
    await axios.get('https://api.trongrid.io/v1/accounts/'+wallet).then(async(res)=>{         
      if(res.status===200){
        balance= Number(res.data.data[0].balance/10**6);          
        if(!balance || balance<=20){
          toast.error('Tron balance is very Low For Gas fee');
          return false;
        }else{
          await contract.approve(swapContract,amount).send(wallet).then(async(resp)=>{
            console.log(resp)
            if(resp=='undefined' || !resp){
              toast.error('something went wrong with token Approval')
              return false
            }else{       
              await axios.get('https://api.trongrid.io/v1/accounts/'+wallet).then(async(res)=>{         
              if(res.status===200){
                balance= Number(res.data.data[0].balance/10**6);          
                if(!balance || balance<=30){
                  toast.error('Tron balance is very Low For Gas fee');
                  return false;
                }else{
                  var swapinstance=await tronWeb.contract().at(swapContract);
                  if(sourceToken=='USDT'){
                    await swapinstance.swapUsdtToSusd(amount).send(wallet).then(async(response)=>{
                      if(response=='undefined' || !response){
                        toast.error('something went wrong with Token Swapping')
                        return false;
                      }else{
                        toast.success(sourceToken+' to '+destinationToken+' Swap  Completed')   
                        setTranscheck('success') 
                      }      
                      }).catch((error)=>{
                        toast.error(error)
                      })
                  }else if(sourceToken=='SUSD'){
                    await swapinstance.swapSusdToUsdt(amount).send(wallet).then(async(response)=>{
                      if(response=='undefined' || !response){
                        toast.error('something went wrong with Token Swapping')
                      }else{
                        toast.success(sourceToken+' to '+destinationToken+' Swap  Completed')   
                        setTranscheck('success') 
                      }      
                      }).catch((error)=>{
                        toast.error(error)
                      })
                  }
                }
              }else{
                toast.error('Unable to get Balance details');
                return false;
              }
              }).catch((err)=>{
                console.log(err)
                toast.error(err);
                return false
              })
              
            }  
          }).catch((err)=>{
            toast.error(err)
          }); 
        }
      }else{
        toast.error('Unable to get Balance details');
        return false;
      }
      }).catch((err)=>{
        console.log(err)
        toast.error(err);
        return false
      })
    
  }
  async function copyToClipboard(){
    if(sourceToken == 'USDT'){
      navigator.clipboard.writeText('TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t');
      toast.success('USDT Contract Copied')
    }else{
      navigator.clipboard.writeText('TXBRbSCyDb1DYVMiNjpSLWz9HKR54tQvNX');
      toast.success('SUSD Contract Copied')
    }
    }
    async function copyToClipboardsusd(){
      if(destinationToken=='SUSD'){
        navigator.clipboard.writeText('TXBRbSCyDb1DYVMiNjpSLWz9HKR54tQvNX');
        toast.success('SUSD Contract Copied')
      }else{
        navigator.clipboard.writeText('TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t');
        toast.success('USDT Contract Copied')
      }
     

    
  }
  function changeSourceToken(){
    if(sourceToken == 'USDT'){
      setSourceToken('SUSD')
      setDestinationToken('USDT')
      checkBalance()

    }
    else{
      setSourceToken('USDT')
      setDestinationToken('SUSD')
      checkBalance()

    }
      
  }
  return (

    <div>
      <div class="area"><ul class="circles"><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul></div>
<div class="swap_wrapp">
    <div class="container">
        <div class="row justify-content-center">
            <div class="s_form_col">
                <h3 class="swap_h3">Swap</h3>
                <div class="swap_form">
                          <Toaster
                             toastOptions={{
                             position:'top-right'
                            }}
                            />
                    <div class="d-flex justify-content-between swap-block">
                        <div class="left-block">
                            <div class="swap_l">From</div>
                            <div class="d-flex input-number">
                                <input placeholder="Enter  amount" type='number' onChange={(e) => {calculatePay(e.target.value)}} value={transcheck=='success'? input :pay}/>
                            </div>
                        </div>
                        <div class="right-block">
                            <div class="swap_l text-end">Balance:  <span>{address ? (sourceToken=='USDT' ? usdtBalance : susdBalance):'--'}</span></div>
                            <div class="d-flex flex-end align-items-center">                            
                                <p class="dragDown">
                                  <img src={sourceToken=='USDT'? usdt : busd}/> &nbsp;
                                  <span>{sourceToken=='USDT'? "USDT" : "SUSD"}</span>                              
                                </p>
                                <button type='button' class="copy_btn mt-2 me-1" onClick={copyToClipboard}>
                                  <svg viewBox="0 0 24 24" color="textSubtle" width="16px" xmlns="http://www.w3.org/2000/svg" class="sc-231a1e38-0 gUXTeE"><path d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM18 21H9C8.45 21 8 20.55 8 20V8C8 7.45 8.45 7 9 7H18C18.55 7 19 7.45 19 8V20C19 20.55 18.55 21 18 21Z"></path></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    
                {!showBalErr ? '':( <div class="safe-amount-tip">
                        <br/>
                        <span style={{color:"red"}}>Your balance is less than the minimum input. For your transaction to be recorded on chain, please reserve at least <b>30 TRX</b> as fees.</span>
                    </div>) }
                   
                    <div class="d-flex justify-content-between setting">
                        <span class="arrdown up_down_btn" onClick={changeSourceToken}>
                        <svg viewBox="0 0 24 24" class="sc-231a1e38-0 dTViYw icon-up-down" color="primary" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M16 17.01V11c0-.55-.45-1-1-1s-1 .45-1 1v6.01h-1.79c-.45 0-.67.54-.35.85l2.79 2.78c.2.19.51.19.71 0l2.79-2.78c.32-.31.09-.85-.35-.85H16zM8.65 3.35L5.86 6.14c-.32.31-.1.85.35.85H8V13c0 .55.45 1 1 1s1-.45 1-1V6.99h1.79c.45 0 .67-.54.35-.85L9.35 3.35a.501.501 0 00-.7 0z"></path></svg>
                        </span>
                    </div>
                    <div class="d-flex justify-content-between swap-block">
                        <div class="left-block">
                            <div class="swap_l">To (Estimated Amount)</div>
                            <div class="d-flex input-number">
                                <input placeholder="Enter an amount" type='number' value={transcheck=='success'? input : pay} onChange={(e)=>calculatePay(e.target.value)}/>
                            </div>
                        </div>
                        <div class="right-block">
                            <div class="swap_l text-end">Balance:  <span>{address ? (destinationToken=='SUSD' ? susdBalance : usdtBalance):'--'}</span></div>
                            <div class="d-flex flex-end">             
                                <p class="dragDown">
                                    <img src={destinationToken=='SUSD'? busd : usdt}/> &nbsp;
                                    <span>{destinationToken=='SUSD'?'SUSD':'USDT'}</span>
                                  
                                </p>
                                <button type='button' class="copy_btn mt-2 me-1" onClick={copyToClipboardsusd}>
                                  <svg viewBox="0 0 24 24" color="textSubtle" width="16px" xmlns="http://www.w3.org/2000/svg" class="sc-231a1e38-0 gUXTeE"><path d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM18 21H9C8.45 21 8 20.55 8 20V8C8 7.45 8.45 7 9 7H18C18.55 7 19 7.45 19 8V20C19 20.55 18.55 21 18 21Z"></path></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                  
                    <div class="mt-35">
                    { (address=='') ?  <button class="connect_btn" type="button"  onClick={() => handleWalletConnect()}>Connect Wallet</button> : <button class="connect_btn" type="button" disabled={!allowSwap} onClick={() => swapToken()}>Swap Token</button>}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    </div>
    
  );
}

export default App;
